import React from 'react';
import {graphql, useStaticQuery, withPrefix} from 'gatsby';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';
import slugify from 'slugify';

import {
  facebookIcon,
  instagramIcon,
  linkedinIcon,
  twitterIcon,
  youtubeIcon,
} from '@workday/third-party-icons-web/dist/system/es6';

import {Hyperlink, TertiaryButton} from '@workday/canvas-kit-react/button';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {Box, Flex} from '@workday/canvas-kit-react/layout';

import {Media} from './Media';

import {
  ViewportSize,
  combineMediaQueries,
  contentHSpace,
  mqAt,
  mqLessThan,
  mqGreaterThan,
  mqGreaterThanOrEqual,
} from '../utils/breakpoints';
import {Image} from '../utils/types';

interface FooterNavProps {
  children: React.ReactNode;
  title: string;
}

const FooterNav = ({children, title}: FooterNavProps) => {
  const titleSlug = slugify(title, {lower: true});
  return (
    <Box
      as="nav"
      aria-labelledby={titleSlug}
      minWidth={146}
      css={{
        a: {
          ...type.levels.subtext.large,
          color: colors.licorice300,
          display: 'inline-block',
          fontWeight: type.properties.fontWeights.medium,
          lineHeight: '20px',
          textDecoration: 'none',
        },

        [mqLessThan('xl')]: {
          marginRight: space.l,

          '&:last-child': {
            marginRight: 0,
          },
        },
      }}
    >
      <Box
        as="h2"
        id={titleSlug}
        color="licorice300"
        paddingY={10}
        css={{
          fontSize: '13px',
          lineHeight: '20px',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Box>
      <ul>{children}</ul>
    </Box>
  );
};

const FooterNavItem = ({...elemProps}) => (
  <li css={{padding: '5px 0', listStyle: 'none'}}>
    <Hyperlink {...elemProps} />
  </li>
);

const SubFooter = () => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    borderTop={`1px solid ${colors.soap400}`}
    css={{
      [mqAt('s')]: {flexDirection: 'column', paddingTop: '44px', paddingBottom: space.m},
      [mqGreaterThan('s')]: {paddingTop: space.m, paddingBottom: space.m},
    }}
  >
    <Flex as="nav" gap="s" aria-label="Social Media">
      <TertiaryButton
        as="a"
        icon={twitterIcon}
        aria-label="Twitter"
        href="https://twitter.com/workday"
        target="_blank"
      />
      <TertiaryButton
        as="a"
        icon={linkedinIcon}
        aria-label="LinkedIn"
        href="https://www.linkedin.com/company/workday"
        target="_blank"
      />
      <TertiaryButton
        as="a"
        icon={facebookIcon}
        aria-label="Facebook"
        href="https://www.facebook.com/workday"
        target="_blank"
      />
      <TertiaryButton
        as="a"
        icon={instagramIcon}
        aria-label="Instagram"
        href="https://www.instagram.com/workday/"
        target="_blank"
      />
      <TertiaryButton
        as="a"
        icon={youtubeIcon}
        aria-label="YouTube"
        href="https://www.youtube.com/user/workdayvideo"
        target="_blank"
        // YouTube icon assigns icon layers in a non-standard way that breaks
        // styling with TertiaryButton. Hack the correct colors in.
        css={{
          '.wd-icon-background': {fill: colors.licorice200},
          '.wd-icon-fill': {fill: `${colors.frenchVanilla100} !important`},
          '&:hover': {
            '.wd-icon-background': {fill: colors.licorice500},
          },
        }}
      />
    </Flex>
    <Flex
      gap="s"
      css={{
        ...type.levels.subtext.medium,

        a: {
          color: colors.blackPepper600,
          fontWeight: type.properties.fontWeights.medium,
          lineHeight: '16px',
          textDecoration: 'none',
        },

        [mqAt('s')]: {
          marginTop: space.l,
        },
      }}
    >
      <Hyperlink href="https://www.workday.com/en-us/privacy.html" target="_blank">
        Privacy
      </Hyperlink>
      <Hyperlink href="https://www.workday.com/en-us/legal.html" target="_blank">
        Legal
      </Hyperlink>
      <p css={{color: colors.licorice300}}>&copy; {new Date().getFullYear()} Workday, Inc.</p>
    </Flex>
  </Flex>
);

export interface FooterProps {
  /**
   * Whether or not to center the Footer on "marketing-style" pages (e.g.,
   * Home and Introduction pages).
   */
  center?: boolean;
}

const maxFooterWidth = 1000;

const getFooterMediaQuery = (size: ViewportSize) => {
  const hPadding = contentHSpace[size];

  return {
    [mqAt(size)]: {
      maxWidth: maxFooterWidth + 2 * hPadding,
      paddingRight: hPadding,
      paddingLeft: hPadding,

      '&:before': {
        marginBottom: size === 's' ? space.xxl : space.xl,
      },
    },
  };
};

const footerStyles = {
  '&:before': {
    content: '""',
    width: '100%',
    height: '4px',
    display: 'block',
    background:
      'linear-gradient(to right, #0875e1, #ffc629 0%, #ffc629 33%, #ffa126 33%, #ffa126 66%, #0875e1 66%, #0875e1 100%)',
  },
  ...combineMediaQueries(getFooterMediaQuery),
};

const generateComponent = (size: 's' | 'greaterThanS') => {
  const {
    allFile: {edges: images},
  } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^footer/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  `);
  const workdayLogoImg = images.find((img: Image) => img.node.name === 'footer-workday-logo')?.node;

  if (size === 'greaterThanS') {
    return (
      <Flex
        justifyContent="space-between"
        paddingBottom="xl"
        css={{
          [mqLessThan('xl')]: {
            flexDirection: 'column',
          },
        }}
      >
        {/* Container for Workday logo and Canvas description */}
        <Flex
          css={{
            [mqLessThan('xl')]: {
              width: '100%',
            },
            [mqGreaterThanOrEqual('xl')]: {
              flexDirection: 'column',
              width: 'calc((100% - 32px) / 2)',
            },
          }}
        >
          <Box>
            <GatsbyImage
              image={getImage(workdayLogoImg) as IGatsbyImageData}
              alt="Workday Logo"
              css={{width: '112px'}}
            />
          </Box>
          <Box
            css={{
              ...type.levels.subtext.large,
              color: colors.licorice300,
              [mqLessThan('xl')]: {marginLeft: space.l},
              [mqGreaterThanOrEqual('xl')]: {marginTop: space.l},
            }}
          >
            <p>
              Workday’s Canvas Design System is an adaptable system of guidelines, components, and
              tools that support the best practices of user interface design. Backed by open-source
              code, Canvas streamlines collaboration between designers and developers, and helps
              teams quickly build beautiful products.
            </p>
          </Box>
        </Flex>
        {/* Container for Footer navigation (Resources, Guidelines, etc.) */}
        <Flex
          css={{
            [mqLessThan('xl')]: {
              marginTop: space.l,
              width: '100%',
            },
            [mqGreaterThanOrEqual('xl')]: {
              justifyContent: 'space-between',
              width: 'calc((100% - 32px) / 2)',
            },
          }}
        >
          <FooterNav title="Resources">
            <FooterNavItem href={withPrefix('/styles')}>Styles</FooterNavItem>
            <FooterNavItem href={withPrefix('/components')}>Components</FooterNavItem>
            <FooterNavItem href={withPrefix('/patterns')}>Patterns</FooterNavItem>
          </FooterNav>
          <FooterNav title="Guidelines">
            <FooterNavItem href={withPrefix('/accessibility')}>Accessibility</FooterNavItem>
            <FooterNavItem href={withPrefix('/content')}>Content</FooterNavItem>
            <FooterNavItem href={withPrefix('/globalization')}>Globalization</FooterNavItem>
          </FooterNav>
          <FooterNav title="Workday">
            <FooterNavItem
              href="https://www.workday.com/en-us/company/about-workday.html"
              target="_blank"
            >
              About Workday
            </FooterNavItem>
            <FooterNavItem
              href="https://www.workday.com/en-us/company/careers.html"
              target="_blank"
            >
              Careers
            </FooterNavItem>
            <FooterNavItem href="https://blogs.workday.com/" target="_blank">
              Blog
            </FooterNavItem>
            <FooterNavItem href="https://developer.workday.com/" target="_blank">
              Extend
            </FooterNavItem>
            <FooterNavItem
              href="https://www.workday.com/en-us/forms/other/sales-contact.html?wdid=enus_ws_footer_contactus_wd_wd_sales_17.0085"
              target="_blank"
            >
              Contact Us
            </FooterNavItem>
          </FooterNav>
        </Flex>
      </Flex>
    );
  }

  // Footer at small viewport size
  return (
    <Box paddingBottom="xxl" css={{textAlign: 'center'}}>
      <GatsbyImage
        image={getImage(workdayLogoImg) as IGatsbyImageData}
        alt="Workday Logo"
        css={{
          width: '112px',

          // Override styles from .gatsby-image-wrapper-constrained which
          // cause odd spacing
          display: 'block',
          margin: '0 auto',
        }}
      />
      <Box as="p" marginTop="l" css={{...type.levels.subtext.large, color: colors.licorice300}}>
        It&apos;s your Canvas
        <br />
        What are you going to create?
      </Box>
    </Box>
  );
};

export const Footer = ({center, ...elemProps}: FooterProps) => {
  return (
    // Seem to need this outer Box for the gradient line to render correctly
    <Box
      as="footer"
      role="contentinfo"
      id="site-footer"
      paddingBottom="xxl"
      marginX={center ? 'auto' : undefined}
      css={footerStyles}
      {...elemProps}
    >
      <Media at="s">{generateComponent('s')}</Media>
      <Media greaterThan="s">{generateComponent('greaterThanS')}</Media>
      <SubFooter />
    </Box>
  );
};
